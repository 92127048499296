const  EnvName = "production";
// const  EnvName = "local";

let key = {};
let IPFS_IMG = 'https://gateway.pinata.cloud/ipfs/'
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
export const ENCODEKEY = process.env.REACT_APP_ENCODEKEY
var Front_market_Url = ''
var BackEndUrl= ''

if (EnvName === "local") {
  Front_market_Url = "https://vertendi.maticz.in/";

  Back_Url = "http://localhost:3331/v1/admin";
  BackEndUrl = 'http://localhost:3331/v1';
  var image_url = "hhttp://localhost:3331/token";
  ImG = "http://localhost:3331";
  var Back_Url_Token = "http://localhost:3331/v1";

  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var BNBCHAIN_NAME = 'MATIC'

  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
 var AdminAddress = "0x0fb556b7a5df171d324d8cb4e42d1ce0caab0c9f"

}
if (EnvName === "demo") {
  Back_Url = "https://backend-vertendi.maticz.in/v1/admin";
   BackEndUrl = 'https://backend-vertendi.maticz.in/v1';

  Front_market_Url = "https://vertendi.maticz.in/";
  var image_url = "https://backend-vertendi.maticz.in/token";
  ImG = "https://backend-vertendi.maticz.in";
  var Back_Url_Token = "https://backend-vertendi.maticz.in/v1";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var BNBCHAIN_NAME = 'MATIC'
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var AdminAddress = "0x0fb556b7a5df171d324d8cb4e42d1ce0caab0c9f"
 
}



if (EnvName === "preproduction") {
  FRONT_URL = " www.vertendi.io";
  Front_market_Url = 'https://api.vertendi.io'
  Back_Url = 'https://api.vertendi.io/v1/admin'
  var image_url = 'https://api.vertendi.io'
  var Back_Url_Token = "https://api.vertendi.io/v1";
  BackEndUrl = 'https://api.vertendi.io/v1';

  ImG ='https://api.vertendi.io';
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var BNBCHAIN_NAME = 'MATIC'
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var AdminAddress = "0x0fb556b7a5df171d324d8cb4e42d1ce0caab0c9f"
 
}



if (EnvName === "production") {
  FRONT_URL = "https://contorls-ytrb.vertendi.io";
  Front_market_Url = 'https://api.vertendi.io'
  Back_Url = 'https://api.vertendi.io/v1/admin'
  var image_url = 'https://api.vertendi.io'
  ImG ='https://api.vertendi.io';
  BackEndUrl = 'https://api.vertendi.io/v1';
  var Back_Url_Token = "https://api.vertendi.io/v1";
  var tradeAddress = '0xe22d6fc8e868b716ce98df7a7afc6459b2a1a112'.toLowerCase()
  var singleAddress ='0xbacc6bb993477fc08ee0678ed0e1e49e074be327'.toLowerCase()
  var multipeAddress = '0x750ea898cb00d5f89d293369fce76e4bf6a3e4b1'.toLowerCase()
  var networkVersion = "137";
  var ETHCHAIN = 137
  var BNBCHAIN = 137
  var BNBCHAIN_NAME = 'MATIC'
  var chainlist = [137,137]
  var chainId = "0x1";
  var BNBversion = "0x38"
  var ETHversion = "0x1"
  var versions =["0x38","0x1"]
  var ETHprovider = "https://lively-sparkling-sun.matic.quiknode.pro/a01b1989cb84596814e4523b79c649317b7d9ad0";
  var BNBprovider = "https://lively-sparkling-sun.matic.quiknode.pro/a01b1989cb84596814e4523b79c649317b7d9ad0";
 var AdminAddress = "0x0fb556b7a5df171d324d8cb4e42d1ce0caab0c9f"
}

key = {
   BNBCHAIN_NAME : BNBCHAIN_NAME , 
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBprovider,
  ETHprovider : ETHprovider,
  ETHCHAIN : ETHCHAIN,
  BNBCHAIN : BNBCHAIN,
  chainlist : chainlist,
  bnbver : BNBversion,
  ethver : ETHversion,
  versionlist :versions ,
  ENVname : EnvName,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  ETHTRADE : ETHTRADE,
  BNBTRADE : BNBTRADE,
  AdminAddress: AdminAddress ,
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion , 
  BackEndUrl :`${BackEndUrl}` 
   
};

export default key;
